import * as React from "react";
import { Helmet } from "react-helmet";
import defaultMetaImage from "../../../static/biosxan.jpg";

/**
 * Generates the apps Head Element.
 */
export default () => {
  const description = "International Covid Vaccination Registry";

  return (
    <>
      <Helmet title="Biosxan" description={description}>
        {/* General tags */}
        <meta name="image" content={defaultMetaImage} />

        {/* OpenGraph tags */}
        <meta property="og:url" content="https://app.biosxan.com" />
        <meta property="og:title" content="Biosxan" />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={defaultMetaImage} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@BioSxan" />
        <meta name="twitter:title" content="Biosxan" />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={defaultMetaImage} />
      </Helmet>
    </>
  );
};
