import React from "react";
import { GetGradientForBackground } from "../../Services/CssService";
import SEO from "../SEO";

import "./global.css";

/**
 * Generates the apps master layout component. This is used to render each child component.
 *
 * @param {Element} children The elements to be rendered within the layout.
 */
const Layout = ({ children }) => (
  <div style={{ backgroundImage: `${GetGradientForBackground()}` }} className="min-h-screen font-body">
    <SEO />
    <div className="container flex justify-center mx-auto">
      <main className="">{children}</main>
    </div>
  </div>
);

export default Layout;
