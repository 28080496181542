/**
 * Determines the background style based on the current route.
 *
 * @returns A String containing the background style to be applied.
 */
export const GetGradientForBackground = () => {
  const greenBackgroundColor = "#07a64d";
  const grayBackgroundColor = "#f2f5fb";

  const upperBackgroundColor = greenBackgroundColor;
  let lowerBackgroundColor = greenBackgroundColor;

  if (typeof window !== "undefined" && window.location) {
    if (window.location.pathname.match(/app/)) {
      lowerBackgroundColor = grayBackgroundColor;
    }
  }
  return `linear-gradient(180deg, ${upperBackgroundColor} 50%, ${lowerBackgroundColor} 50%)`;
};
